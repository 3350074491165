.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  @include transition($transition-collapse);
  position: relative;
  height: 0;
  overflow: hidden;
}

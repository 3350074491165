// Inline code
code {
  @include font-size($code-font-size);
  color: $code-color;
  word-break: break-word;

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    color: inherit;
  }
}

// User input typically entered via keyboard
kbd {
  @include font-size($kbd-font-size);
  @include border-radius($border-radius-sm);
  @include box-shadow($kbd-box-shadow);
  background-color: $kbd-bg;
  color: $kbd-color;
  padding: $kbd-padding-y $kbd-padding-x;

  kbd {
    @include font-size(100%);
    @include box-shadow(none);
    font-weight: $nested-kbd-font-weight;
    padding: 0;
  }
}

// Blocks of code
pre {
  @include font-size($code-font-size);
  display: block;
  color: $pre-color;

  // Account for some code outputs that place code tags in pre tags
  code {
    @include font-size(inherit);
    color: inherit;
    word-break: normal;
  }
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: $pre-scrollable-max-height;
  overflow-y: scroll;
}

.header-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background-color: #5e72e4;
  opacity: 0.3;
}

#navbar-main {
  overflow: visible;
}

.skew-separator {
  overflow: hidden !important;
}

.header-video youtube-player {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 0;
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.video-text {
  color: #fff;
  font-size: 4em;
  z-index: 20;
}

.page-header {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 80vh;
  overflow: hidden;
  padding: 0;
}

.header-4 .header-video {
  overflow: hidden !important;
}

// .skew-separator::after {
//   content: "";
//   position: absolute;
//   top: 95%;
//   z-index: 2;
//   width: 100%;
//   height: 800px;
//   background: #fff;
//   transform: skewY(-8deg);
// }

*,
::after,
::before {
  box-sizing: border-box;
}

header {
  position: relative;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 28px;
  line-height: 1;
  overflow: hidden;
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  overflow: hidden;
}

//
// Mask
//

.mask {
  @include transition($transition-base);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Backdrop

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040; // navbar fixed has a z-index of 1030
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.backdrop-dark {
  background: rgba($black, 0.3);
}

.backdrop-light {
  background: rgba($white, 0.3);
}

//
// Blurable
// add a blue effect on hover on any element with .blur--hover class
//

.blur--hover {
  position: relative;

  .blur-item {
    filter: blur(0);
    opacity: 1;
    transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    will-change: transform;
  }

  .blur-hidden {
    position: absolute;
    top: calc(50% + 7px);
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: $transition-base;
  }

  &:hover {
    .blur-item {
      z-index: 1;
      transform: scale(0.95);
      filter: blur(10px);
      opacity: 0.8;
    }

    .blur-hidden {
      top: 50%;
      opacity: 1;
    }
  }
}

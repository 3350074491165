//
// Form extend
//

// Color variations

.form-primary {
  .form-control {
    border-color: darken(theme-color("primary"), 10%);
    background-color: transparentize(darken(theme-color("primary"), 10%), 0.5);
    color: color-yiq(theme-color("primary"));

    &:focus {
      border: $input-border-width solid darken(theme-color("primary"), 12%);
      background-color: transparentize(darken(theme-color("primary"), 12%), 0.3);
    }

    // Placeholder
    &::placeholder {
      color: transparentize(color-yiq(theme-color("primary")), 0.2);
    }
  }

  .input-group-text {
    border-color: darken(theme-color("primary"), 10%);
    background-color: transparentize(darken(theme-color("primary"), 10%), 0.5);
    color: color-yiq(theme-color("primary"));
  }

  .focused {
    .input-group-text {
      border-color: darken(theme-color("primary"), 12%);
      background-color: transparentize(darken(theme-color("primary"), 12%), 0.3);
      color: color-yiq(theme-color("primary"));
    }
  }
}

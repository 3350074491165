@mixin reset-text {
  font-weight: $font-weight-normal;
  font-family: $font-family-base;
  // We deliberately do NOT reset font-size or word-wrap.
  font-style: normal;
  line-break: auto;
  line-height: $line-height-base;
  letter-spacing: normal;
  white-space: normal;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-transform: none;
  text-decoration: none;
  text-shadow: none;
  word-break: normal;
  word-spacing: normal;
}

//
// Close
//

.close {
  @if $enable-transitions {
    transition: $transition-base;
  }

  & > span:not(.sr-only) {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: $close-bg;
    color: $close-color;
    font-size: 1.25rem;
    line-height: 17px;
    @if $enable-transitions {
      transition: $transition-base;
    }
  }

  &:hover,
  &:focus {
    outline: none;
    background-color: $close-hover-bg;
    color: $close-hover-color;

    span:not(.sr-only) {
      background-color: $close-hover-bg;
    }
  }
}

.close-dark {
  & > span:not(.sr-only) {
    color: rgba(255, 255, 255, 0.8);
  }

  &:hover,
  &:focus {
    & > span:not(.sr-only) {
      color: rgba(255, 255, 255, 1);
    }
  }
}

//
// Heading
//

// General styles

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  color: inherit;
}

.heading {
  font-weight: $heading-font-weight;
  font-size: $heading-font-size;
  letter-spacing: $heading-letter-spacing;
  text-transform: $heading-text-transform;
}

// Heading variations

.heading-small {
  font-size: 0.75rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.heading-title {
  font-weight: $heading-title-font-weight;
  font-size: $heading-title-font-size;
  letter-spacing: $heading-title-letter-spacing;
  text-transform: $heading-title-text-transform;
}

.heading-section {
  font-weight: $heading-section-font-weight;
  font-size: $heading-section-font-size;
  letter-spacing: $heading-section-letter-spacing;
  text-transform: $heading-section-text-transform;

  img {
    display: block;
    width: 72px;
    height: 72px;
    margin-bottom: 1.5rem;
  }

  &.text-center {
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Surtitles

.surtitle {
  color: $gray-600;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
}

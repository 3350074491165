@mixin badge-variant($bg) {
  background-color: lighten($bg, 32%);
  color: saturate(darken($bg, 10%), 10);

  &[href] {
    @include hover-focus {
      background-color: darken($bg, 12%);
      color: color-yiq($bg);
      text-decoration: none;
    }
  }
}

// Disable animation if transitions are disabled
@if $enable-transitions {
  @keyframes progress-bar-stripes {
    from {
      background-position: $progress-height 0;
    }

    to {
      background-position: 0 0;
    }
  }
}

.progress {
  @include font-size($progress-font-size);
  @include border-radius($progress-border-radius);
  @include box-shadow($progress-box-shadow);
  display: flex;
  height: $progress-height;
  background-color: $progress-bg;
  overflow: hidden; // force rounded corners by cropping it
}

.progress-bar {
  @include transition($progress-bar-transition);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $progress-bar-bg;
  color: $progress-bar-color;
  white-space: nowrap;
  text-align: center;
}

.progress-bar-striped {
  @include gradient-striped();
  background-size: $progress-height $progress-height;
}

@if $enable-transitions {
  .progress-bar-animated {
    animation: progress-bar-stripes $progress-bar-animation-timing;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
}

//
// Navbar search
//

.navbar-search {
  .input-group {
    @include transition($transition-bg);
    border: $navbar-search-border-width solid;
    border-radius: $navbar-search-border-radius;
    transition-delay: $transition-base-time;

    .input-group-text {
      border: 0;
      background-color: transparent;
      padding-left: 1rem;
    }
  }

  .form-control {
    @include transition($navbar-search-transition);
    width: $navbar-search-width;
    border: 0;
    background-color: transparent;
  }

  .focused {
    .input-group {
      .form-control {
        width: $navbar-search-focus-width;
      }
    }
  }

  .close {
    display: none;
  }
}

// Search variations

.navbar-search-dark {
  .input-group {
    border-color: $navbar-search-dark-border-color;
    background-color: $navbar-search-dark-bg;
  }

  .input-group-text {
    color: $navbar-search-dark-color;
  }

  .form-control {
    color: $navbar-search-dark-focus-color;

    &::placeholder {
      color: $navbar-search-dark-color;
    }
  }

  .focused {
    .input-group {
      border-color: $navbar-search-dark-focus-border-color;
      background-color: $navbar-search-dark-focus-bg;
    }
  }
}

.navbar-search-light {
  .input-group {
    border-color: $navbar-search-light-border-color;
    background-color: $navbar-search-light-bg;
  }

  .input-group-text {
    color: $navbar-search-light-color;
  }

  .form-control {
    color: $navbar-search-light-focus-color;

    &::placeholder {
      color: $navbar-search-light-color;
    }
  }

  .focused {
    .input-group {
      border-color: $navbar-search-light-focus-border-color;
      background-color: $navbar-search-light-focus-bg;
    }
  }
}

// When navbar search is open on xs
@include media-breakpoint-down(xs) {
  .navbar-search {
    @include transition($transition-cubic-bezier);
    display: none;
    width: 100%;
    transform: translateX(-150%);

    .form-group {
      width: 100%;
    }

    .form-control {
      width: auto;
    }

    .focused {
      .input-group {
        .form-control {
          width: auto;
        }
      }
    }

    .close {
      display: none;
      opacity: 0;

      span {
        width: auto;
        height: auto;
      }
    }
  }

  .navbar-top {
    .navbar-nav {
      @include transition($transition-base);
    }
  }

  .g-navbar-search-showing {
    .navbar-search {
      .close {
        display: block;
      }
    }

    .navbar-top {
      .navbar-nav {
        display: flex;
        transform: translateX(150%);
        opacity: 1;
      }
    }
  }

  .g-navbar-search-show {
    // Search
    .navbar-search {
      display: block;

      .form-control {
        width: auto;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 46px;
        height: 46px;
        color: white;
        line-height: 46px;
        text-align: center;
        cursor: pointer;
      }
    }

    // Navbar
    .navbar-top {
      .navbar-nav {
        display: none;
      }

      // Collapse
      .navbar-collapse {
        width: 100%;
      }
    }
  }

  .g-navbar-search-shown {
    .navbar-search {
      transform: translateX(0);

      .close {
        display: block;
        opacity: 1;
      }
    }
  }

  .g-navbar-search-hiding {
    // Navbar
    .navbar-top {
      .navbar-nav {
        display: flex;
        transform: translateX(150%);
        opacity: 0;
      }
    }
  }

  .g-navbar-search-hidden {
    .navbar-top {
      .navbar-nav {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

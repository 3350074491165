//
// Checklist
// works with the [data-toggle="checklist"] which inits the JS for item selection
//

// Checklist entry
// is used in JS to get each entry and verify if it checked

.checklist-entry {
}

// Checklist item
// contains the checklist entry info and checkbox

.checklist-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.75rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    border-radius: 8px;
    background-color: theme-color("primary");
  }
}

.checklist-item-checked {
  .checklist-info * {
    text-decoration: line-through;
  }
}

// Color variations

@each $color, $value in $theme-colors {
  .checklist-item-#{$color} {
    &::before {
      background-color: theme-color($color);
    }
  }
}

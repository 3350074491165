p {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.7;
}

h5 {
  font-weight: 400 !important;
  font-size: 1.25rem !important;
}

* {
  overflow-x: hidden;
}

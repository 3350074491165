@media (max-width: 576px) {
  #vector-map {
    svg {
      width: 100%;
      margin: 0 auto;

      .dxm-control-bar {
        display: none;
      }

      .dxm-layers {
        transform: translate(-200px);
      }
    }
  }
}
@media (max-width: 981px) {
  #vector-map {
    svg {
      width: 100%;
      margin: 0 auto;

      .dxm-control-bar {
        display: none;
      }

      .dxm-layers {
        transform: translate(-200px);
      }
    }
  }
}

#vector-map svg {
  margin: 0 auto;
}
